import http from "../http-common";

const setPick = (user, game, team) => {
    return http.post("/picks", {
        user: user,
        game: game,
        team: team
    });
}

const getAll = () => {
    return http.get("/picks");
}

const findByUser = (user) => {
    return http.get(`/picks/${user}`);
}

const findByWeek = (week) => {
    return http.get(`/picks?week=${week}`);
}

const PickDataService = {
    setPick,
    getAll,
    findByUser,
    findByWeek
};

export default PickDataService;