import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AddPickem from "./components/AddPickem";
import Pickem from "./components/Pickem";
import PickemList from "./components/PickemList";
import GameList from "./components/GameList";
import UserLogin from "./components/UserLogin";
import Standings from "./components/Standings";
import Container from 'react-bootstrap/Container';
import PickComparer from "./components/PickComparer";
import SeasonDataService from "./services/SeasonService";


const { DateTime } = require("luxon");

const App = () => {
  const [week, setWeek] = useState(null);

  const getCurrentWeek = async () => {
    const curDate = DateTime.now().startOf("day");
    const format = "MM-dd-yyyy";

    let currentWeek;

    let response = await SeasonDataService.getWeeks()
    let weeks = response.data;

    for (var i = 0; i < weeks.length - 1; i++){
      let curEnd = DateTime.fromISO(weeks[i].weekEnd);

      if (curDate <= curEnd) {
        currentWeek = weeks[i].week;
        break;
      }
      else{
        currentWeek = weeks[i].week + 1;
      }
    }

    console.log(`currentWeek: ${currentWeek}`);
    return currentWeek;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let curWeek = await getCurrentWeek();
        console.log(`curWeek: ${curWeek}`);
        setWeek(curWeek);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  if (week === null){
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="m-0 p-0">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <a href="/" className="navbar-brand">
          NFL Pick'em
        </a>
        <div className="navbar-nav mr-auto">
          {/* <li className="nav-item">
            <Link to={"/games"} className="nav-link">
              Games
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to={"/standings"} className="nav-link">
              Standings
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/compare"} className="nav-link">
              Who's Pickin Who
            </Link>
          </li>
        </div>
      </nav>
      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<UserLogin week={week}/>} />
          <Route path="/pickems" element={<PickemList/>} />
          <Route path="/add" element={<AddPickem/>} />
          <Route path="/pickems/:id" element={<Pickem/>} />
          <Route path="/games" element={<GameList week={week} admin={false}/>} />
          <Route path="/standings" element={<Standings/>} />
          <Route path="/admin" element={<GameList admin={true}/>} />
          <Route path="/compare" element={<PickComparer week={week}/>} />
        </Routes>
      </div>
    </Container>
  );
};

export default App;
