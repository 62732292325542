import React, { useState, useEffect } from "react";
import GameDataService from "../services/GameService";
import PickDataService from "../services/PickService";
import ScoreDataService from "../services/ScoreService";
import UserDataService from "../services/UserService";
import {useLocation} from 'react-router-dom';
import TeamPickBox from "./TeamPickBox";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';

const { DateTime } = require("luxon");

const GameList = (props) => {
    const location = useLocation();

    const [picksAndGames, setPicksAndGames] = useState({});
    const [pickResults, setpickResults] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const [user, setUser] = useState(location.state ? location.state.user : 1);
    const [users, setUsers] = useState([]);
    const [week, setWeek] = useState(location.state ? location.state.week : 1);
    const [userScore, setUserScore] = useState({});
    const [adminMode, setAdminMode] = useState(false);
    
    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            retrievePicksAndGames();
            retrieveUserScore();
            retrieveUsers();
            if (props.admin) {
                setAdminMode(props.admin);
            }
        }
        updatePickResults();
    }, [picksAndGames]);

    useEffect(() => {
        retrievePicksAndGames();
        retrieveUserScore();
    }, [week, user]);
    
    
    const retrievePicksAndGames = () => {
        let picks;
        let games;
        PickDataService.findByUser(user)
            .then(response => {
                picks = response.data;
            })
            .then(() => {
                GameDataService.findByWeek(week)
                    .then(response => {
                        games = response.data;
                    })
                    .then(() => {
                        setPicksAndGames({picks: picks, games: games});
                    })
                    .catch(e => {
                        console.log(e);
                    });
            })
            .catch(e => {
                console.log(e);
            });
    };

    const updatePickResults = () => {
        var results = {};

        if (!picksAndGames.games){
            return;
        }

        for (var game of picksAndGames.games) {
            if (!game.winnerId && !game.tie) {
                results[game.id] = 'none';
                continue;
            }

            var pick = picksAndGames.picks.find(p => p.gameId == game.id);

            if (!pick) {
                results[game.id] = 'incorrect';
                        continue;
            }
            else {
                if (game.winnerId) {
                    results[game.id] = game.winnerId == pick.teamId ? 'correct' : 'incorrect';
                }
                else if (game.tie) {
                    results[game.id] = 'incorrect';
                }
            }
        }

        setpickResults(results);
    }

    const retrieveUserScore = () => {
        ScoreDataService.getUserScoreByWeek(user, week)
            .then(response => {
                setUserScore(response.data);
            });
    }

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then(response => {
                setUsers(response.data);
            });
    }

    const refreshList = () => {
        retrievePicksAndGames();
    };

    const updatePick = async (user, game, team) => {
        // console.log(`posting pick: user: ${user}, game: ${game}, team: ${team}`);
        await PickDataService.setPick(user, game, team)
            .then(() => refreshList());
    }

    return (
        <Container className="col-md-8" fluid>
                <Dropdown hidden={!adminMode} onSelect={(e) => setUser(e)}>
                    <Dropdown.Toggle>
                        Choose User
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {users.map((usr, idx) => (
                            <Dropdown.Item eventKey={usr.id}>
                                {usr.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <h3>{`Hi, ${(users.find(u => u.id == user) || {name: null}).name}`}</h3>
                <h4>Your Picks</h4>
                <h5>{`Week ${week}`}</h5>
                <ListGroup horizontal className="mb-4 mw-200 overflow-auto">
                    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map((wk, idx) => (
                        <ListGroup.Item
                                key={idx}
                                action 
                                onClick={() => setWeek(wk)}
                                active={wk == week}
                            >
                            {wk}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <Stack>
                    {picksAndGames.games &&
                        picksAndGames.games.map((game, index) => (
                            <Container key={index} className={`border ${pickResults[game.id] == 'none' ? 'bg-light' : pickResults[game.id] == 'correct' ? 'bg-success' : 'bg-danger'}`} fluid>
                                    <Row>
                                        <Col className={``}>
                                                <TeamPickBox
                                                    user={user}
                                                    game={game.id}
                                                    team={game.away}
                                                    pick={(picksAndGames.picks.find(p => p.gameId == game.id) || { teamId: null }).teamId}
                                                    pickable={(DateTime.fromISO(game.time) > DateTime.now()) || adminMode}
                                                    onPick={updatePick}
                                                />
                                                <TeamPickBox
                                                    user={user}
                                                    game={game.id}
                                                    team={game.home}
                                                    pick={(picksAndGames.picks.find(p => p.gameId == game.id) || { teamId: null }).teamId}
                                                    pickable={(DateTime.fromISO(game.time) > DateTime.now()) || adminMode}
                                                    onPick={updatePick}
                                                />
                                        </Col>
                                        <Col>
                                            <div>
                                                <div>
                                                    {DateTime.fromISO(game.time).toFormat('h:mma ZZZZ')}
                                                </div>
                                                <div>
                                                    {DateTime.fromISO(game.time).toFormat('EEE, MMM d')}                                                    
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                        ))}
                </Stack>
                <Stack className="bg-light border mt-4 mb-4 p-2">
                    <div>
                        {`Correct picks: ${userScore.correct}`}
                    </div>
                    <div>
                        {`Points: ${userScore.points || 0}`}
                    </div>
                </Stack>
            </Container>
    );
};

export default GameList;