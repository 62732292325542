import http from "../http-common";

const getWeeks = () => {
    return http.get(`/seasons/weeks`);
}

const SeasonDataService = {
    getWeeks
}

export default SeasonDataService;