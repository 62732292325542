import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Table from 'react-bootstrap/Table';
import ScoreDataService from "../services/ScoreService";
import ListGroup from 'react-bootstrap/ListGroup';

const Standings = props => {
    const [standings, setStandings] = useState([]);
    const [weeklyStandings, setWeeklyStandings] = useState([]);
    const [week, setWeek] = useState('Overall');
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            retrieveStandings();
            retrieveWeeklyStandings();
        }
    });
    
    useEffect(() => {
        console.log(`week updated to: ${week}`);
    }, [week]);

    const retrieveStandings = () => {
        ScoreDataService.getStandings()
            .then(response => {
                setStandings(response.data);
            });
    }
    
    const retrieveWeeklyStandings = () => {
        ScoreDataService.getStandingsWeekly()
            .then(response => {
                setWeeklyStandings(response.data);
            });
    }


    return (
        <Container>
            <ListGroup horizontal className="mb-4 mw-200 overflow-auto">
                    {['Overall',1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map((wk, idx) => (
                        <ListGroup.Item
                                key={idx}
                                action 
                                onClick={() => setWeek(wk)}
                                active={wk == week}
                            >
                            {wk}
                        </ListGroup.Item>
                    ))}
            </ListGroup>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Correct Picks</th>
                        <th>Points</th>
                    </tr>
                </thead>
                <tbody>
                    {week == 'Overall' ? standings.map((standing, index) => (
                        <tr key={index}>
                            <td>{standing.name}</td>
                            <td>{standing.correct}</td>
                            <td>{standing.points}</td>
                        </tr>
                    )) : weeklyStandings.filter(s => s.week === week).map((standing, index) => (
                        <tr key={index}>
                            <td>{standing.name}</td>
                            <td>{standing.correct}</td>
                            <td>{standing.points}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}

export default Standings;