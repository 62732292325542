import http from "../http-common";

const getAll = () => {
    return http.get("/users");
}

const UserDataService = {
    getAll
};

export default UserDataService;