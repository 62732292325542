import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Table from 'react-bootstrap/Table';
import GameDataService from "../services/GameService";
import PickDataService from "../services/PickService";
import UserDataService from "../services/UserService";
import ListGroup from 'react-bootstrap/ListGroup';

const PickComparer = props => {    
    const [week, setWeek] = useState(props.week || 1);
    const [users, setUsers] = useState([]);
    const [games, setGames] = useState([]);
    const [picks, setPicks] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            retrieveGames();
            retrievePicks();
            retrieveUsers();
        }
        console.log(picks);
    });
    
    useEffect(() => {
        console.log(`week updated to: ${week}`);
        retrieveGames();
        retrievePicks();
    }, [week]);

    const retrieveGames = () => {
        GameDataService.findByWeek(week)
            .then(response => setGames(response.data))
            .catch(e => console.log(e.message));
    }

    const retrievePicks = () => {
        console.log(`retrieving picks for week ${week}`);
        PickDataService.findByWeek(week)
            .then(response => setPicks(response.data))
            .catch(e => console.log(e.message));
    }

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then(response => setUsers(response.data))
            .catch(e => console.log(e.message));
    }

    let rowColor = 0;

    return (
        <Container>
            <ListGroup horizontal className="mb-4 mw-200 overflow-auto">
                    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map((wk, idx) => (
                        <ListGroup.Item
                                key={idx}
                                action 
                                onClick={() => setWeek(wk)}
                                active={wk == week}
                            >
                            {wk}
                        </ListGroup.Item>
                    ))}
            </ListGroup>
            <Container className="overflow-auto p-0 m-0">
                <Table striped={false} bordered className="m-0">
                    <thead style={{position: "sticky"}}>
                        <tr>
                            <th>Team</th>
                            {users &&
                                users.map((user, idx) => (
                                    <th>{user.name}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {games &&
                            games.map((game, idx) => (
                                <React.Fragment>{[
                                    <tr className={rowColor % 2 == 0 ? 'table-light' : 'table-secondary'}>
                                        <td>{game.away.name}</td>
                                        {users &&
                                            users.map((usr, uidx) => (
                                                <td key={uidx}>{picks.some(p => p.gameId == game.id && p.teamId == game.away.id && p.userId == usr.id) ? '☑️' : ''}</td>
                                            ))}
                                    </tr>,
                                    <tr className={rowColor++ % 2 == 0 ? 'table-light' : 'table-secondary'}>
                                        <td>{game.home.name}</td>
                                        {users &&
                                            users.map((usr2, uidx2) => (
                                                <td key={uidx2}>{picks.some(p => p.gameId == game.id && p.teamId == game.home.id && p.userId == usr2.id) ? '☑️' : ''}</td>
                                            ))}
                                    </tr>
                                ]}</React.Fragment>
                            ))}
                    </tbody>
                </Table>
            </Container>
        </Container>
    )
}

export default PickComparer;