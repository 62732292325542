import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';

const TeamPickBox = props => {
    const [isLoading, setLoading] = useState(false)

    const handleClick = async () => {
        if (isLoading) {
            return;
        }

        setLoading(true);

        try {
            await props.onPick(props.user, props.game, props.team.id);
        }
        catch (error) {
            console.log('pick failed');
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Button
            style={{margin: 5, width: 200}}
            variant={props.pick == props.team.id ? "primary" : "secondary"}
            onClick={handleClick}
            disabled={props.pickable ? false : true}
        >
            {props.team.name}
        </Button>
    )
}

export default TeamPickBox;