import React, { useState } from "react";
import PickemDataService from "../services/PickemService";

const AddPickem = () => {
    const initialPickemState = {
        id: null,
        title: "",
        description: "",
        season: "",
        active: false,
        submitted: false
    };

    const [pickem, setPickem] = useState(initialPickemState);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setPickem({ ...pickem, [name]: value });
    };

    const savePickem = () => {
        var data = {
            title: pickem.title,
            description: pickem.description
        };

        PickemDataService.create(data)
            .then(response => {
                setPickem({
                    id: response.data.id,
                    title: response.data.title,
                    description: response.data.description,
                    active: response.data.active
                });
                setSubmitted(true);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const newPickem = () => {
        setPickem(initialPickemState);
        setSubmitted(false);
    }

    return (
        <div className="submit-form">
            {submitted ? (
                <div>
                    <h4>You submitted successfully!</h4>
                    <button className="btn btn-success" onClick={newPickem}>
                        Add
                    </button>
                </div>
            ) : (
                <div>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            required
                            value={pickem.title}
                            onChange={handleInputChange}
                            name="title"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            required
                            value={pickem.description}
                            onChange={handleInputChange}
                            name="description"
                        />
                    </div>
                    <button onClick={savePickem} className="btn btn-success">
                        Submit
                    </button>
                </div>
            )}
        </div>
    );
};

export default AddPickem;
