import http from "../http-common";

const getAll = () => {
    return http.get("/games");
}


const findByWeek = (week) => {
    return http.get(`/games/${week}`);
}

const GameDataService = {
    getAll,
    findByWeek
};

export default GameDataService;