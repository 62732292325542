import http from "../http-common";

const getAll = () => {
    return http.get("/pickems");
}

const get = (id) => {
    return http.get(`/pickems/${id}`);
}

const create = (data) => {
    return http.post("/pickems", data);
}

const update = (id, data) => {
    return http.put(`/pickems/${id}`, data);
}

const deletePickem = (id) => {
    return http.delete(`/pickems/${id}`);
}

const deleteAll = () => {
    return http.delete(`/pickems`);
}

const findByTitle = (title) => {
    return http.get(`/pickems?title=${title}`);
}

const PickemDataService = {
    getAll,
    get,
    create,
    update,
    deletePickem,
    deleteAll,
    findByTitle
};

export default PickemDataService;