// import { response } from "express";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PickemDataService from "../services/PickemService";

const Pickem = props => {
    const { id } = useParams();
    let navigate = useNavigate();

    const initialPickemState = {
        id: null,
        title: "",
        description: "",
        active: false,
        season: ""
    };

    const [currentPickem, setCurrentPickem] = useState(initialPickemState);
    const [message, setMessage] = useState("");

    const getPickem = id => {
        PickemDataService.get(id)
            .then(response => {
                setCurrentPickem(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (id) {
            getPickem(id);
        }
    }, [id]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentPickem({ ...currentPickem, [name]: value });
    };

    const updateActive = status => {
        var data = {
            id: currentPickem.id,
            title: currentPickem.title,
            description: currentPickem.description,
            season: currentPickem.season,
            active: status
        };

        PickemDataService.update(currentPickem.id, data)
            .then(response => {
                setCurrentPickem({ ...currentPickem, active: status });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const updatePickem = () => {
        PickemDataService.update(currentPickem.id, currentPickem)
            .then(response => {
                console.log(response.data);
                setMessage("The pickem was updated successfully");
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deletePickem = () => {
        PickemDataService.deletePickem(currentPickem.id)
            .then(response => {
                console.log(response.data);
                navigate("/pickems");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div>
            {currentPickem ? (
                <div className="edit-form">
                    <h4>Pickem</h4>
                    <form>
                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                name="title"
                                value={currentPickem.title}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                value={currentPickem.description}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>
                                <strong>Status:</strong>
                            </label>
                            {currentPickem.active ? "Active" : "Inactive"}
                        </div>
                    </form>
                    {currentPickem.active ? (
                        <button
                            className="badge badge-primary mr-2"
                            onClick={() => updateActive(false)}
                        >
                            UnPublish
                        </button>
                    ) : (
                        <button
                            className="badge badge-primary mr-2"
                            onClick={() => updateActive(true)}
                        >
                            Activate
                        </button>
                    )}
                    <button className="badge badge-danger mr-2" onClick={deletePickem}>
                        Delete
                    </button>
                    <button
                        type="submit"
                        className="badge badge-success"
                        onClick={updatePickem}
                    >
                        Update
                    </button>
                    <p>{message}</p>
                </div>
            ) : (
                <div>
                    <br />
                    <p>Please click on a Pickem...</p>
                </div>
            )}
        </div>
    );
};

export default Pickem;

