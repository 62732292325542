import http from "../http-common";

const getUserScore = (user) => {
    return http.get(`/scores/${user}`);
}

const getUserScoreByWeek = (user, week) => {
    return http.get(`/scores/${user}/${week}`);
}

const getStandings = () => {
    return http.get('scores/standings');
}

const getStandingsWeekly = () => {
    return http.get('scores/standings/weekly');
}

const GameDataService = {
    getUserScore,
    getUserScoreByWeek,
    getStandings,
    getStandingsWeekly
};

export default GameDataService;