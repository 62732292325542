import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserDataService from "../services/UserService";
import Button from 'react-bootstrap/Button';

const UserLogin = (props) => {
    const [users, setUsers] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [week, setWeek] = useState(props.week);

    useEffect(() => {
        if(firstLoad) {
            setFirstLoad(false);
            retrieveUsers();
        }
    });

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then(response => {
                setUsers(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div>
            <h1>NFL Pick'em</h1>
            <h2>Please select your account</h2>
            <ul className="list-group">
                {users &&
                    users.map((user, index) => (
                        <li
                            key={index}
                            className={"list-group-item"}>
                            <Link
                                to="/games"
                                state={{user: user.id, week: week}}
                                style={{ textDecoration: 'none' }}
                            >
                                {user.name}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default UserLogin;